<template>
	<div>
		<v-container v-if="hasPermission($route.meta.permission)" id="user-profile">
			<v-dialog overlay-opacity=".5" v-model="please_wait_dialog" width="300">
				<v-card color="#2196F3" dark>
					<v-card-text class="white--text">
						Please wait ...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-row class="ma-0">
				<v-col cols="12"><span class="text-h2">Services</span></v-col>
			</v-row>
			<v-row class="ma-0">
				<v-col cols="6" sm="4" md="3" xl="2" class="me-auto">
					<v-text-field
						v-model="globalSearch"
						prepend-inner-icon="mdi-magnify"
						name="name"
						label="Search Services"
						dense
						class="white"
						outlined
						hide-details
					></v-text-field>
				</v-col>
				<v-col cols="2">
					<v-btn class="float-end me-0" color="primary">
						Ask new Service
					</v-btn>
				</v-col>
			</v-row>
			<v-col
				cols="12"
				class="pa-0"
				v-for="(service, main_srv_idx) in servicesList"
				:key="service.main_service_id"
			>
				<v-card flat class="ma-0">
					<v-simple-table
						class="mb-6 pa-3 rounded-lg"
						style="border: 1px solid lightgrey"
						min-height="100%"
					>
						<template v-slot:default>
							<tr style="border: 2px solid grey" v-if="main_srv_idx == 0">
								<th>Service Name</th>
								<th>Service Summary</th>
								<!-- <th>Branch Assigned</th> -->
								<th>Unit Cost</th>
								<th>Min Sales Price</th>
								<th>Active</th>
							</tr>
							<tr class="blue-grey lighten-5 rounded-lg ps-3">
								<td
									width="200"
									class="text-center"
									style="color: rgb(59, 58, 58)"
								>
									<h1>{{ service.name }}</h1>
								</td>
								<td width="500" class="text-center">
									{{
										(service.description_en == null && "N/A") ||
										service.description_en
									}}
								</td>
								<td class="200">
									<div class="text-center">-</div>
								</td>

								<td width="200" colspan="">
									<div class="d-flex justify-center">
										<span
											>{{ service.min_cost }}
											<span class="grey--text">SAR</span>
										</span>

										<v-menu
											v-model="service.menu"
											:close-on-content-click="false"
											:nudge-width="-150"
											dense
											offset-x
										>
											<template v-slot:activator="{ on, attrs }">
												<v-icon
													ref="edi"
													v-bind="attrs"
													v-on="on"
													@click="editMinSale(service)"
													class="ms-2"
													>mdi-pencil</v-icon
												>
											</template>

											<v-card class="pa-3 ma-0 rounded-lg" elevation="0">
												<span class="text-h4 font-weight-bold"
													>Min sale price</span
												>
												<v-text-field
													style="width: 150px"
													class="mt-3"
													hide-details
													:rules="[
														(val) => val > 0 && val !== null && val !== ``,
													]"
													suffix="SAR"
													@keyup.enter="editMinSale(service)"
													dense
													type="number"
													outlined
													v-model="minSaleToEdit"
												>
												</v-text-field>

												<v-btn
													color="white"
													text
													small
													class="rounded-pill primary mt-3"
													@click="updateMinCost(service)"
												>
													Save
												</v-btn>
											</v-card>
										</v-menu>
									</div>
								</td>
								<td class="text-center">-</td>
							</tr>
							<tr
								v-for="(
									subService, subService_idx
								) in service.selected_sub_services"
								:key="`${subService.id}-${subService_idx}`"
							>
								<td width="200" class="text-center">{{ subService.name }}</td>
								<td width="700" class="text-center">
									{{
										(subService.description_en == null && "N/A") ||
										subService.description_en
									}}
								</td>
								<!-- <td class="text-center">{{ subService.branches_assigned }}</td> -->
								<td style="width: 100px">
									<div class="text-center d-flex justify-center">
										<span>{{ subService.agreement_price }} </span>&nbsp;<span
											class="grey--text"
											>SAR</span
										>
									</div>
								</td>

								<td width="200" class="text-center">
									<div>-</div>
								</td>

								<td
									width="100"
									v-if="hasPermission('Activate and deactivate services')"
								>
									<v-switch
										:true-value="1"
										:false-value="0"
										@click="updateSubServiceStatus(subService)"
										:label="subService.status ? 'Active' : 'Inactive'"
										v-model="subService.status"
									></v-switch>
								</td>
							</tr>
						</template>
					</v-simple-table>
				</v-card>
				<!-- <v-btn class="float-end rounded-pill" color="primary" @click="updateMinCost">Update</v-btn> -->
			</v-col>
		</v-container>
		<v-container v-else class="pa-10" id="regular-tables" tag="section">
			<v-row>
				<v-col cols="12" class="d-flex justify-center">
					<v-card flat>
						<!-- <v-card-title
						><v-card class="red">
							<h1 class="red pa-6">
								You Don't have permission to view this page !
							</h1></v-card
						></v-card-title
					> -->
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>

<script>
export default {
	data() {
		return {
			minSaleToEdit: 0,
			mainServiceToEdit: null,
			subServiceToEdit: null,
			subService_idx_ToEdit: null,
			subServiceStatusToEdit: null,
			editMenu: false,
			globalSearch: null,
			token: localStorage.getItem("token"),
			servicesList: [],
			please_wait_dialog: false,
			partner_details: null,
			user_id: localStorage.getItem("userid"),
		};
	},

	mounted() {
		this.please_wait_dialog = true;
		this.getServices();
	},
	methods: {
		updateSubServiceStatus(subService) {
			this.please_wait_dialog = true;
			axios("/api/v3/b2b/update-partner-sub-service", {
				method: "POST",
				headers: { "X-Authorization": this.token },
				data: { sub_service_id: subService.id, status: subService.status },
			})
				.then()
				.finally(() => (this.please_wait_dialog = false));
		},

		getServices() {
			axios
				.get("/api/v3/b2b/services-by-partner-id", {
					headers: { "X-Authorization": this.token },
					params: { partner_id: this.user_id },
				})
				.then(({ data }) => {
					this.partner_details = data.partner_detail;
					this.servicesList = data.partner_services;
					this.please_wait_dialog = false;
				})
				.catch((err) => (this.please_wait_dialog = false))
				.finally(() => (this.please_wait_dialog = false));
		},
		updateMinCost(service) {
			this.please_wait_dialog = true;
			axios("/api/v3/b2b/update-partner-main-service", {
				method: "POST",
				headers: { "X-Authorization": this.token },
				data: {
					main_service_id: service.main_service_id,
					min_cost: this.minSaleToEdit,
				},
			})
				.then(() => {
					service.menu = !service.menu;
					this.getServices();
				})
				.finally(() => (this.please_wait_dialog = false));
		},
		editMinSale(service) {
			service.menu === false || service.menu == null
				? (this.minSaleToEdit = service.min_cost)
				: 0;
			service.menu = !service.menu;
		},
	},
};
</script>

